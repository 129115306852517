import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import * as $ from 'jquery';
// used as $().DataTable()
import { DataTable } from 'react-jquery-datatables'; // eslint-disable-line no-unused-vars
import 'react-jquery-datatables/dist/vendor/datatables.min.css';
import axios from 'axios';
import azure from '../assets/azurestoragejs/azure-storage.blob.js'; // https://github.com/Azure/azure-storage-js
import { Base64 } from 'js-base64';
import filesize from 'filesize';
import Reaptcha from 'reaptcha';
import { AllHtmlEntities } from 'html-entities';
import Popup from "reactjs-popup";

// I(ternationalizatio)n:
import { withTranslation } from 'react-i18next';
import { setTimeout } from 'timers';


class Upload extends Component {

    constructor(props) {
        super(props);

        // Determine the requested folder:
        let requestedFolderPath = null;
        if (this.props.location && this.props.location.pathname) {
            requestedFolderPath = this.props.location.pathname;
            if (requestedFolderPath.length > 1) {
                requestedFolderPath = requestedFolderPath.substr(1);
            } else {
                requestedFolderPath = null;
            }
        }

        this.state = {
            folderId: requestedFolderPath,
            folderName: null,
            folder_good: false,
            uploadStatus: false,
            uploadAllowedCnt: 0,
            fileUploadHandles: {},
            fileUploadProgresses: {},
            files_uploaded_cnt: 0,
            files_uploaded: [],
            display_recaptcha: true,
            display_done: false,
            session_id: null,
            files_to_upload_queue: [],
            nextUploadNeedsConfirm: false,
            maxUploadsExceeded: false
        }

        // Make sure this on/off is outside state.
        // State will update asynchronously.
        this.files_can_done = false;
        this.upload_idx = 0;

        // Metadata updates
        this.metadata_update = {
            change_id: 0,
            sender: {},
            files: {},
            timeout: null
        };

        // Upload components
        this.upload_table = null;
        this.uploadButton1 = null;
        this.uploadButton2 = null;
        this.uploadPopup = null;

        // ReCAPTCHA
        this.recaptcha = null;
        this.recaptcha_div = null;
        this.re_publicKey = null;
        if (this.props.appconfig)
            this.re_publicKey = this.props.appconfig.reCAPTCHA_PublicKey;
        if (!this.re_publicKey) {
            console.log("Internal error! Configuration failed.");

            // Fail the page immediately.
            this.state.folderId = null;
            this.state.display_recaptcha = false;
        }

        // API
        this.API = axios.create({
            baseURL: `${this.props.appconfig.APIhost}/api/v1/`,
            headers: { Accept: 'application/json' }
        });
    }

    componentDidMount = () => {
        const { t } = this.props;

        // I18n: https://datatables.net/reference/option/language
        this.upload_table = $('#uploads').DataTable({
            "autoWidth": false,
            "paging": false,
            "searching": false,
            "bSort": true,
            //"order": [[2, "asc"]],
            "language": {
                "info": t('upload:upload-table.paging-text'),
                "emptyTable": t('upload:upload-table.empty-table'),
                "infoEmpty": '',
            },
            "columnDefs": [
                {
                    // Column 0 will use own renderer
                    "targets": 0,
                    "render": function (data, type, row, meta) {
                        if (!data || !(type === 'display' || type === 'filter' || type === 'sort')) {
                            return data;
                        }

                        if (type === 'filter' || type === 'sort') {
                            return data.name;
                        }

                        // Return our own HTML when displaying this cell
                        const entities = new AllHtmlEntities();
                        let cell_html = `
<span id="upload_success_${row['DT_RowId']}" class="rounded-button upload-success pull-right" style="display: none;">
	<i class="glyphicon glyphicon-ok"></i>
</span>
<span id="upload_failure_${row['DT_RowId']}" class="rounded-button upload-failure pull-right" style="display: none;">
	<i class="glyphicon glyphicon-remove"></i>
</span>
<div class="upload-filename">${entities.encode(data.name)}</div>
<div class="upload-description">${entities.encode(data.desc)}</div>
<progress id="upload_progress_${row['DT_RowId']}" class="upload-progress" max="100" value="0" />`;

                        return cell_html;
                    }
                },
                {
                    "targets": 1,
                    "render": function (data, type, row, meta) {
                        if (type !== 'display') {
                            return data;
                        }

                        let size = filesize(data).replace(' ', String.fromCharCode(160));

                        return size;
                    }
                }
            ]
        });

        this.doCaptchaCheck();
    }

    componentWillUnmount = () => {
        // cf. https://github.com/sarneeh/reaptcha/issues/97
        this.removeRecaptchaIframe();
    }

    // hack to prevent timeout error from Google's recaptcha script
    // modeled after https://github.com/dozoisch/react-google-recaptcha/commit/50dcd4381fba9bda05c08f7bd6160ae4420fb1fb
    removeRecaptchaIframe = () => {
        if (! this.recaptcha_div) {
            return;
        }
        const temporaryNode = document.createElement("div");
        document.body.appendChild(temporaryNode);
        temporaryNode.style.display = "none";

        // move of the recaptcha HTML to a temporary node
        while (this.recaptcha_div.firstChild) {
            temporaryNode.appendChild(this.recaptcha_div.firstChild);
        }
        // delete the temporary node after Google's recaptcha reset has completed
        setTimeout(() => {
            document.body.removeChild(temporaryNode);
        }, 5000);
    }

    doCaptchaCheck = () => {
        const self = this;

        // Prevent scrolling:
        $('body').addClass("no-scroll-body");

        // CORS-wise GET, HEAD, POST are simple requests. No Origin: applied.
        this.API.get(`folder/${this.state.folderId}`, {
            withCredentials: true
        }).then((response) => {
            const json_response = response.data;
            // Store response, including the DB-name of the folder
            self.setState({
                display_recaptcha: json_response.need_recaptcha,
                folder_good: (json_response.folder_good === true),
                folderId: json_response.folder_path,
                folderName: json_response.folder_name,
                session_id: json_response.session_id,
                uploadAllowedCnt: json_response.uploads_left,
                nextUploadNeedsConfirm: false
            });
            //console.log(`XXX Result, reCAPTCHA display: ${json_response.need_recaptcha}`, response.data);
            setTimeout(() => {
                $('#g-recaptcha-response').attr('aria-labelledby', 'recaptcha_label');
            }, 1000);
        }).catch((error) => {
            console.log(`Result, reCAPTCHA-check failed`, error);
        });
    }

    addFileToUpload = (ev, input) => {
        ev.preventDefault();
        //console.log(`addFileToUpload() uploads_left: ${this.state.uploadAllowedCnt - filesToUpload.length}`);

        if (!input || !input.files) {
            console.log("No files to add into upload queue.");
            return;
        }

        const self = this;
        const filesToUpload = this.state.files_to_upload_queue;
        let maxUploadsExceeded = this.state.maxUploadsExceeded;
        Array.prototype.forEach.call(input.files, (file) => {
            const uploads_left = self.state.uploadAllowedCnt - filesToUpload.length;
            if (uploads_left > 0) {
                file.upload_idx = ++self.upload_idx;
                filesToUpload.push(file);
            } else {
                console.log(`Error: Has ${uploads_left} uploads, won't upload ${file.name}`);
                maxUploadsExceeded = true;
            }
        });
        this.setState({ files_to_upload_queue: filesToUpload, maxUploadsExceeded });

        // Clear the selected values:
        input.value = '';
    }

    removeFileFromUpload = (upload_idx, ev) => {
        if (ev)
            ev.preventDefault();
        //console.log(`removeFileFromUpload() file: ${upload_idx}`);

        let matching_idx = null;
        this.state.files_to_upload_queue.map((file, idx) => {
            if (file.upload_idx === upload_idx)
                matching_idx = idx;

            return null;
        });

        if (matching_idx != null) {
            let filesToUpload = this.state.files_to_upload_queue;
            filesToUpload.splice(matching_idx, 1);
            this.setState({ files_to_upload_queue: filesToUpload });
        }
    }

    uploadCancelled = () => {
        this.setState({ files_to_upload_queue: [], maxUploadsExceeded: false });
    }

    openUploadDialogClicked = (ev, input) => {
        console.log(`openUploadDialogClicked() file: ${input.id}`);
        if (this.state.nextUploadNeedsConfirm) {
            /* XXX Disabled displaying the reCATPCHA on subsequent attempts.
            ev.preventDefault();
            this.setState({ display_recaptcha: true });
            */
        }
    }

    handleUploadFile = (ev) => {
        ev.preventDefault();

        const self = this;
        this.state.files_to_upload_queue.forEach((file, idx) => {
            // Get file description, if any
            const desc_input = $(`#upload-queue-file-desc-${file.upload_idx}`)[0];

            // Add new row to uploads table: filename + description / size
            const row_data = {
                DT_RowId: `row-${file.upload_idx}`,
                0: { name: file.name, desc: desc_input.value },
                1: file.size
            };
            const row = self.upload_table.row.add(row_data);

            // Re-draw to make sure the newly added row is in its sort-order slot.
            row.invalidate();
            self.upload_table.draw();

            // Initialize the progress hash for this file. It will keep the file table visible.
            // Decrement upload count
            const progressHash = self.state.fileUploadProgresses;
            progressHash[file.upload_idx] = 0.0;
            self.setState({
                fileUploadProgresses: progressHash,
                nextUploadNeedsConfirm: true,
                maxUploadsExceeded: false
            });

            // Pass file information as seen by client
            const meta_data = {
                "session_id": self.state.session_id,
                "folder": self.state.folderId,
                "lastModified": file.lastModified,
                "filename": file.name,
                "size": file.size,
                "type": file.type,
                "description": Base64.encode(desc_input.value)
            };
            self.handleUploadFile_init(file, meta_data);
        });

        // Clear the upload queue. This will close the upload pop-up.
        this.uploadCancelled(ev);

        // Scroll to the top of the upload button
        this.uploadButton1.parentElement.parentElement.scrollIntoView();
    }

    handleUploadFile_init = (file, request_body) => {
        const self = this;

        this.API.post('upload', request_body, {
        }).then((response) => {
            const json_response = response.data;
            //console.log(`Got shared access token: $(json_response.token.access_token)`);
            // For .createBlobServiceWithSas(), see: https://azure.github.io/azure-storage-node/global.html
            const accessToken = json_response.token;
            const exponentialRetryPolicyFilter = new azure.ExponentialRetryPolicyFilter();
            const blobService = azure.createBlobServiceWithSas(
                accessToken.host_uri, accessToken.access_token
            ).withFilter(exponentialRetryPolicyFilter);
            // See: https://docs.microsoft.com/en-us/rest/api/storageservices/understanding-block-blobs--append-blobs--and-page-blobs
            //const options = { blockSize: 104857600 }; // 100 MiB, max size = max. blob slightly more than 4.75 TB (100 MB X 50,000 blocks)
            const options = { blockSize: 10485760 }; // 10 MiB * 50.000 = max. blob 488 GiB
            //const options = { blockSize: 1048576 }; // 1 MiB = max. blob 48 GiB

            console.log("Info: Begin uploading blob: " + accessToken.filename);
            // For .createBlockBlobFromBrowserFile(), see: https://azure.github.io/azure-storage-node/BlobService.html
            const speedSummary = blobService.createBlockBlobFromBrowserFile(
                accessToken.container,
                accessToken.filename,
                file,
                options,
                self.handleUploadFile_cb
            );
            /*
            { name: 'c94526a7-f305-42fa-99e9-d7b472da5f39',
            _startTime: 1538661832554,
            _timeWindowInSeconds: 10,
            _timeWindow: 10000,
            _totalWindowSize: 0,
            _speedTracks: [ , , , , , , , , ,  ],
            _speedTrackPtr: 0,
            totalSize: undefined,
            completeSize: 0 }
                */

            // Upload progress
            speedSummary.on('progress', function () {
                self.handleUploadFile_progress(speedSummary);
            });
            const progressHash = self.state.fileUploadProgresses;
            const info = self.state.fileUploadHandles;
            const file_info = {
                req: request_body,
                summary: speedSummary,
                upload_idx: file.upload_idx,
                failed: false
            };
            progressHash[file.upload_idx] = 0.0;
            info[speedSummary.name] = file_info;

            self.setState({
                fileUploadProgresses: progressHash,
                fileUploadHandles: info
            });
        }).catch((error) => {
            const json_response = error.response && error.response.data;
            // Something went wrong
            if (json_response && json_response.need_recaptcha) {
                console.log("Session expired!");
                self.setState({ display_recaptcha: true });
                self.doCaptchaCheck();
            } else {
                console.log("Failed to get upload SAS!");
                self.removeFileFromUpload(file.upload_idx);
            }

            const progressHash = self.state.fileUploadProgresses;
            progressHash[file.upload_idx] = -1.0; // Negative progress == failure!

            self.setState({
                fileUploadProgresses: progressHash,
            });
            self.displayFileUploadResult(file.upload_idx, false);
        });
    }

    handleUploadFile_cb = (err, result, response, extra) => {
        if (err) {
            console.log("Error! Upload failed!");
            // If using out-of-the-box azure-storage.blob.js there is no extra-parameter.
            if (extra && extra.blob) {
                console.log("Info: Failing blob was: " + extra.blob);

                // Don't track progress for a failed file.
                if (this.state.fileUploadHandles && extra.blob in this.state.fileUploadHandles) {
                    const info = this.state.fileUploadHandles;
                    const upload_idx = info[extra.blob].upload_idx;
                    const filename = info[extra.blob].req ? info[extra.blob].req.filename : undefined;
                    const progressHash = this.state.fileUploadProgresses;

                    progressHash[upload_idx] = -1.0; // Negative progress == failure!
                    info[extra.blob].failed = true;

                    this.setState({
                        fileUploadProgresses: progressHash,
                        fileUploadHandles: info
                    });
                    this.displayFileUploadResult(upload_idx, false);

                    // Tell server, that client thinks the file has failed to upload.
                    const request_body = {
                        "session_id": this.state.session_id,
                        "folder": this.state.folderId,
                        "upload_done": true
                    };
                    
                    this.API.request({ // cf. https://github.com/axios/axios/issues/3220
                        method: 'delete', // axios.delete with config.data broken in 0.20.0!
                        url: `upload/${extra.blob}`, 
                        data: request_body // NB: axios.delete needs the request body in config.data! 
                    }).then((response) => {
                        console.log(`Info: Flagged file ${filename} upload as failed.`, response.data);
                    }).catch((error) => {
                        console.log(`Error: Failed to flag file ${filename} upload as failed.`, error);
                    });
                }
            }
        }
        if (result && response) {
            console.log("Info: Upload completed.");
            const filename = result.name;
            const status = response.isSuccessful;
            /*
            { container: 'uploads',
            name: 'c94526a7-f305-42fa-99e9-d7b472da5f39',
            lastModified: 'Thu, 04 Oct 2018 14:03:53 GMT' }
             */

            /*
            { isSuccessful: true,
            statusCode: 201,
            body: '',
            headers: { 'last-modified': 'Thu, 04 Oct 2018 14:06:13 GMT' },
            md5: undefined }
             */

            if (status) {
                console.log("Info: Uploaded blob was: " + filename);

                // Tell server, that client thinks the file has been uploaded in full.
                const request_body = {
                    "session_id": this.state.session_id,
                    "folder": this.state.folderId,
                    "upload_done": true
                };

                this.API.put(`upload/${filename}`, request_body, {
                }).then((response) => {
                    console.log(`Info: Flagged file ${filename} upload as done!`, response.data);
                }).catch((error) => {
                    console.log(`Error: Failed to flag file ${filename} upload as done.`, error);
                });
            } else {
                console.log("Error: Uploaded blob was: " + filename + ", but didn't get success.");
            }
        }
    }

    handleUploadFile_progress = (speedSummary) => {
        /*
        { name: '139d9878-f71a-41d3-8aa1-c95a65aaf62c',
          _startTime: 1539158638675,
          _timeWindowInSeconds: 10,
          _timeWindow: 10000,
          _totalWindowSize: 0,
          _speedTracks: [ { timeStamp: 1539158639253, size: 1438086 }, , , , , , , , ,  ],
          _speedTrackPtr: 0,
          totalSize: 1438086,
          completeSize: 1438086,
          _events: { progress: [Function] },
          _eventsCount: 1 }
         */
        const progressPercent = parseFloat(speedSummary.getCompletePercent());
        const info = this.state.fileUploadHandles;
        let progressHash = this.state.fileUploadProgresses;
        const file_idx = info[speedSummary.name].upload_idx;

        if (progressPercent > 98.0) {
            console.log(`Info: Upload nearly done. Upload progress ${progressPercent} for ${file_idx} (${speedSummary.name})`);
        }
        progressHash[file_idx] = progressPercent;
        this.displayFileUploadProgress(file_idx, progressPercent);

        if (Object.keys(progressHash).length) {
            let newFiles = {};
            Object.keys(progressHash).forEach((filename) => {
                let fileProgressPerc = progressHash[filename];
                // Uploads between 0.0 and 99.9 are ongoing
                if (fileProgressPerc < 100 && fileProgressPerc >= 0.0) {
                    newFiles[filename] = fileProgressPerc;
                }

                return null;
            });
        }

        if (progressPercent >= 100) {
            const total = this.state.files_uploaded_cnt + 1;
            this.setState({
                files_uploaded_cnt: total,
                fileUploadProgresses: progressHash
            });
            this.displayFileUploadResult(file_idx, true);
        } else {
            this.setState({ fileUploadProgresses: progressHash });
        }
    }

    displayFileUploadProgress = (file_idx, percentage) => {
        const progress_bar = $(`#upload_progress_row-${file_idx}`)[0];
        if (progress_bar) {
            //console.log(`Info: Set progress bar value into ${percentage} for ${file_idx} (${speedSummary.name})`);
            progress_bar.value = percentage;
        }
    }

    displayFileUploadResult = (file_idx, success) => {
        const result = success ? 'success' : 'failure';
        const success_mark = $(`#upload_${result}_row-${file_idx}`)[0];
        const progress_bar = $(`#upload_progress_row-${file_idx}`)[0];

        if (progress_bar) {
            progress_bar.style.display = 'none';
        }
        if (success_mark)
            success_mark.style.display = '';
    }

    onReCaptchaVerify = (recaptcha_response) => {
        const self = this;
        const request_body = {
            "folder": this.state.folderId,
            "response": Base64.encode(recaptcha_response)    // Base64 encoded to get past WAF OWASP 3.0 ruleset
        };
        //console.log(`Verifying reCAPTCHA: ${recaptcha_response}`);

        this.API.put('recaptcha', request_body, {
        }).then((response) => {
            const json_response = response.data;
            if (json_response.need_recaptcha === false) {
                // reCAPTCHA passed
                self.recaptcha.reset();
                self.setState({
                    display_recaptcha: json_response.need_recaptcha,
                    folder_good: (json_response.folder_good === true),
                    folderId: json_response.folder_path,
                    folderName: json_response.folder_name,
                    uploadAllowedCnt: json_response.uploads_left,
                    nextUploadNeedsConfirm: false
                });

                // Allow scrolling:
                $('body').removeClass("no-scroll-body");
            }
        }).catch((error) => {
            console.log(`ReCaptcha failed to verify!`, error);
        });
    }

    onReCaptchaExpire = (ev) => {
        console.log("reCAPTCHA expired! Next time, be faster!");
    }

    onFormDone = (ev) => {
        ev.preventDefault();

        if (this.files_can_done) {
            this.setState({ display_done: true });

            this.API.delete(`folder/${this.state.folderId}`, {
            }).then((response) => {
                console.log(`Info: Session was removed.`, response.data);
            }).catch((error) => {
                console.log(`Error: Failed to remove session.`, error);
            });
            this.props.history.push('/kiitos');
        }
    }

    onMetadataChanged = (ev, origin) => {
        const new_value = ev.target.value;
        if (!origin) {
            origin = ev.target.id;
        }
        if (!origin) {
            console.log("Internal error! onMetadataChanged() called with incomplete information.");
        } else {
            //console.log(`onMetadataChanged() called from ${origin} with value: "${new_value}"`);
        }

        const row_regexp = new RegExp("^row-(.+)+_text$");
        let changed_any = false;
        switch (origin) {
            case "extra-details":
                changed_any = true;
                this.metadata_update.sender.extra = Base64.encode(new_value);
                break;
            case "uploader-name":
                changed_any = true;
                this.metadata_update.sender.name = new_value;
                break;
            case "uploader-email":
                changed_any = true;
                this.metadata_update.sender.email = new_value;
                break;
            case "uploader-tel":
                changed_any = true;
                this.metadata_update.sender.phone = new_value;
                break;
            case "uploader-org":
                changed_any = true;
                this.metadata_update.sender.organization = new_value;
                break;

            default:
                const row_match = row_regexp.exec(origin);
                if (row_match) {
                    const file_id = row_match[1];
                    changed_any = true;
                    this.metadata_update.files[file_id] = new_value;
                } else {
                    console.log(`Internal: Metadata has unknown origin: "${origin}"`);
                }
                break;
        }

        if (!changed_any) {
            // Some sort of failure.
            // We didn't get what to change.
            return true;
        }

        ++this.metadata_update.change_id;
        if (this.metadata_update.timeout) {
            // There is an ongoing update running.
            return true;
        }

        this.doSendMetadataUpdate();
    }

    doSendMetadataUpdate = () => {
        const self = this;
        const change_id_in = this.metadata_update.change_id;
        const request_body = {
            "session_id": this.state.session_id,
            "folder": this.state.folderId,
            "sender": this.metadata_update.sender,
            "files": this.metadata_update.files
        };

        this.metadata_update.timeout = setTimeout(() => {
            this.API.put('upload', request_body, {
            }).then((response) => {
                console.log(`onMetadataChanged() data updated ok.`, response.data);
            }).catch((error) => {
                console.log(`onMetadataChanged() data update failed!`, error);
            }).then(() => {
                // Reset timer, another attempt can be made
                self.metadata_update.timeout = null;

                // Doing another round?
                //console.log(`XXX doSendMetadataUpdate(), end count: ${change_id_in}, count now: ${self.metadata_update.change_id}`);
                if (self.metadata_update.change_id > change_id_in) {
                    self.doSendMetadataUpdate();
                } else {
                    // Reset data to be sent
                    self.metadata_update = {
                        change_id: 0,
                        sender: {},
                        files: {},
                        timeout: null
                    };
                }
            });
        }, 1500);

        return true;
    }

    updateGoogleCaptchaLanguageIfChanged = (selectedLanguage) => {
        // Base for this code from: https://gist.github.com/athlonUA/ed5b19ec905d354609d4f58fe863db9a
        const upload_disabler_div = document.getElementById('upload_disabler');
        if (!upload_disabler_div)
            return null;
        const iframeGoogleCaptcha = upload_disabler_div.getElementsByTagName('iframe')[0];
        if (!iframeGoogleCaptcha)
            return null;
        const current_language = iframeGoogleCaptcha.src.match(/hl=(.*?)&/).pop();
        if (current_language === selectedLanguage)
            return false;

        iframeGoogleCaptcha.src = iframeGoogleCaptcha.src.replace(/hl=(.*?)&/, 'hl=' + selectedLanguage + '&');

        return true;
    }

    render = () => {
        const { t, i18n } = this.props;
        const self = this;

        // reCAPTCHA, 100% React-native implementation: https://github.com/sarneeh/reaptcha
        // Known language codes: https://developers.google.com/recaptcha/docs/language
        const possible_language_codes = ['ar', 'af', 'am', 'hy', 'az', 'eu', 'bn', 'bg', 'ca', 'zh-HK',
            'zh-CN', 'zh-TW', 'hr', 'cs', 'da', 'nl', 'en-GB', 'en', 'et', 'fil', 'fi', 'fr', 'fr-CA',
            'gl', 'Value', 'ka', 'de', 'de-AT', 'de-CH', 'el', 'gu', 'iw', 'hi', 'hu', 'is', 'id', 'it',
            'ja', 'kn', 'ko', 'lo', 'lv', 'lt', 'ms', 'ml', 'mr', 'mn', 'no', 'fa', 'Value', 'pl', 'pt',
            'pt-BR', 'pt-PT', 'ro', 'ru', 'sr', 'si', 'sk', 'sl', 'es', 'es-419', 'sw', 'sv', 'ta', 'te',
            'th', 'tr', 'uk', 'ur', 'vi', 'zu'];
        // Get Translationext detected (or user selected) language and hack it to match Google reCAPTCHA allowed ones.
        let recaptcha_lang = (i18n.language || '');
        if (possible_language_codes.indexOf(recaptcha_lang) === -1) {
            recaptcha_lang = recaptcha_lang.substr(0, 2);
            if (possible_language_codes.indexOf(recaptcha_lang) === -1) {
                recaptcha_lang = '';
            }
        }
        //console.log(`reCAPTCHA UI language: ${recaptcha_lang}`);
        this.updateGoogleCaptchaLanguageIfChanged(recaptcha_lang);

        //console.log(`XXX Displaying reCAPTCHA: ${this.state.display_recaptcha}`);
        if (!this.state.display_recaptcha) {
            // Allow scrolling:
            $('body').removeClass("no-scroll-body");
        }

        const upload_disabler = (
            <div id="upload_disabler"
                style={{ display: this.state.display_recaptcha ? 'block' : 'none' }}
                ref={(d) => this.recaptcha_div = d}>
                <div id="upload_disabler_inner">
                    <div id="upload_disabler_inner2">
                        <label id="recaptcha_label" className="hidden">{t('upload:action.recaptcha-response')}</label>
                        <Reaptcha
                            ref={(r) => this.recaptcha = r}
                            sitekey={this.re_publicKey}
                            hl={recaptcha_lang}
                            onVerify={this.onReCaptchaVerify}
                            onExpire={this.onReCaptchaExpire}
                        />
                    </div>
                </div>
            </div>
        );
        // the recaptcha iframe must not be removed or 
        // a timeout exception will be thrown to console from Google's script
        if (this.state.display_done) {
            return this.render_thank_you(t, upload_disabler);
        } else if (!this.state.folder_good && !this.state.display_recaptcha) {
            return this.render_folder_bad(t, upload_disabler);
        }

        let displayUploadPopup = false;
        let uploads_disabled = false;
        let maxInfoDisplay = {
            display: 'none'
        };
        let uploadQueueList = (<div className="upload-queue"></div>);
        if (this.state.files_to_upload_queue.length) {
            displayUploadPopup = true;
            if (this.state.files_to_upload_queue.length >= this.state.uploadAllowedCnt) {
                uploads_disabled = true;
            }
            if (this.state.maxUploadsExceeded) {
                // User attempted to upload more than max. files at one time.
                maxInfoDisplay = {
                    display: 'block'
                };
            }

            const filesInUploadQueue = this.state.files_to_upload_queue.map((file, idx) => {
                return (
                    <tr id={`upload-queue-file-${file.upload_idx}`} key={`upload-${file.upload_idx}`}>
                        <td>
                            <div className="file-name">{file.name}</div>
                            <div className="file-description">
                                <input type="text" className="extra-info-field" maxLength="1999"
                                    id={`upload-queue-file-desc-${file.upload_idx}`}
                                    placeholder={t('upload:upload-popup.file-description')}
                                    aria-labelledby="file_description_label"
                                    onChange={(ev) => self.onMetadataChanged(ev)} />
                            </div>
                        </td>
                        <td>
                            <button className="rounded-button upload-remove pull-right"
                                title={t('upload:upload-popup.file-remove')}
                                aria-label={t('upload:upload-popup.file-remove')}
                                onClick={(ev) => self.removeFileFromUpload(file.upload_idx, ev)}>
                                <i className="glyphicon glyphicon-remove" aria-hidden="true"></i>
                            </button>
                            <div className="file-size">{filesize(file.size).replace(' ', String.fromCharCode(160))}</div>
                        </td>
                    </tr>
                );
            });

            uploadQueueList = (
                <div className="upload-queue">
                    <table className="table" aria-labelledby="queue-list">
                        <thead>
                            <tr>
                                <th className="col-xs-8 col-sm-9 col-md-10">
                                    <span>{t('upload:upload-table.file-name')}</span>
                                    <span className="hidden"> | <label id="file_description_label">{t('upload:upload-popup.file-description')}</label></span>
                                </th>
                                <th className="col-xs-4 col-sm-3 col-md-2">{t('upload:upload-table.file-size')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filesInUploadQueue}
                        </tbody>
                    </table>
                </div>
            );
        }

        let uploadProgressPerc = 0;
        let uploadsOngoing = 0;
        let uploadsAtThisTime = 0;
        if (Object.keys(this.state.fileUploadProgresses).length) {
            ++uploadsOngoing;
            let newProgressPerc = 0;
            Object.keys(this.state.fileUploadProgresses).forEach((filename) => {
                let fileProgressPerc = self.state.fileUploadProgresses[filename];
                // Uploads between 0.0 and 99.9 are ongoing
                if (fileProgressPerc < 100 && fileProgressPerc >= 0.0) {
                    ++uploadsAtThisTime;
                    newProgressPerc += fileProgressPerc;
                }

                return null;
            });
            if (uploadsAtThisTime)
                uploadProgressPerc = Math.ceil(newProgressPerc / uploadsAtThisTime);
        }
        if (uploadProgressPerc === 0 && this.state.files_uploaded_cnt > 0 && uploadsAtThisTime === 0) {
            // If any files are uploaded ever. Make sure progress is at 100%.
            uploadProgressPerc = 100;
        }

        let hiddenRow = 'hidden';
        if (this.state.files_uploaded_cnt === 0) {
            // If there is something in upload queue, go display the table
            if (this.state.files_to_upload_queue.length || uploadsOngoing) {
                hiddenRow = '';
            }

            // Nothing uploaded. Ever.
            this.files_can_done = false;
        } else if (uploadsAtThisTime) {
            hiddenRow = '';
            // Ongoing upload
            this.files_can_done = false;
        } else {
            hiddenRow = '';
            // Files have been uploaded. Nothing going on right now.
            this.files_can_done = true;
        }

        return (
            <div>
                {upload_disabler}
                <Row>
                    <Col xsHidden sm={2} md={3}></Col>
                    <Col xs={12} sm={8} md={6}>
                        <h2>{t('upload:folder-info.header')}</h2>
                        <p>{t('upload:folder-info.paragraph1')}</p>
                        <p>{t('upload:folder-info.paragraph2')}</p>
                        <p className="subject-text">{t('upload:folder.topic')}: {this.state.folderName === null ? "" : this.state.folderName}</p>
                        <p>
                            <span className="upload-btn-wrapper">
                                <span className="upload-btn">
                                    <button className="rounded-button upload-select" disabled={uploads_disabled}
                                        aria-label={t('upload:action.choose-files')}>+</button>
                                    <label htmlFor="file_selection" className={uploads_disabled ? 'disabled' : ''}>
                                        <strong>{t('upload:action.choose-files')}</strong>
                                    </label>
                                </span>
                                <input type="file" multiple="multiple" disabled={uploads_disabled}
                                    title={t('upload:action.choose-files')}
                                    id="file_selection" ref={(c) => this.uploadButton1 = c}
                                    onClick={(ev) => this.openUploadDialogClicked(ev, this.uploadButton1)}
                                    onChange={(ev) => this.addFileToUpload(ev, this.uploadButton1)} />
                            </span>
                        </p>

                        <Popup
                            open={displayUploadPopup}
                            onClose={this.uploadCancelled}
                            ref={(c) => this.uploadPopup = c}
                            overlayStyle={{ overflow: "scroll" }}
                            contentStyle={{ maxWidth: "800px", width: "80%", padding: "30px" }}
                            closeOnDocumentClick={false} lockScroll={true} modal={true}>
                            {close => (
                                <div className="modal" id="upload_modal">
                                    <button className="close rounded-button upload-close" onClick={close}
                                        title={t('upload:upload-popup.close')}
                                        aria-label={t('upload:upload-popup.close')}>
                                        <i className="glyphicon glyphicon-remove" aria-hidden="true"></i>
                                    </button>
                                    <h2 id="queue-list">{t('upload:upload-popup.header')}</h2>
                                    {uploadQueueList}
                                    <p className="queue-file-select">
                                        <span className="upload-btn-wrapper">
                                            <span className="upload-btn">
                                                <button className="rounded-button upload-select" disabled={uploads_disabled}
                                                    aria-label={t('upload:action.choose-files')}>+</button>
                                                <label htmlFor="file_selection2" className={uploads_disabled ? 'disabled' : ''}>
                                                    <strong>{t('upload:action.choose-files')}</strong>
                                                </label>
                                            </span>
                                            <input type="file" multiple="multiple" disabled={uploads_disabled}
                                                title={t('upload:action.choose-files')}
                                                id="file_selection2" ref={(c) => this.uploadButton2 = c}
                                                onChange={(ev) => this.addFileToUpload(ev, this.uploadButton2)} />
                                        </span>
                                    </p>
                                    <div className="text-center">
                                        <p className="upload-warning" style={maxInfoDisplay}>
                                            <i className="glyphicon glyphicon-alert"></i> {t('upload:upload-popup.max-file-info')}
                                        </p>
                                        <p>
                                            <button type="button" className="rounded-button submit-btn" onClick={this.handleUploadFile}>{t('upload:upload-popup.send')}</button>
                                        </p>
                                        <p className="upload-note">
                                            {t('upload:upload-popup.info1')}<br />
                                            {t('upload:upload-popup.info2')}
                                        </p>
                                        <p className="upload-cancel">
                                            <button onClick={close}>{t('upload:action.cancel')}</button>
                                        </p>
                                    </div>
                                </div>
                            )}
                        </Popup>

                        <div className={hiddenRow}>
                            <h3 id="uploads-list">{t('upload:upload-table.header')}</h3>
                            <table id="uploads" className="table" aria-labelledby="uploads-list">
                                <thead>
                                    <tr>
                                        <th className="col-xs-9"><div>{t('upload:upload-table.file-name')}</div></th>
                                        <th className="col-xs-3"><div>{t('upload:upload-table.file-size')}</div></th>
                                    </tr>
                                </thead>
                                <tbody></tbody>
                            </table>

                            <h3>{t('upload:extra-info.header')}</h3>
                            <p>{t('upload:extra-info.paragraph1')}</p>
                            <p>
                                <label htmlFor="extra-details">{t('upload:extra-info.details')}</label><br />
                                <textarea id="extra-details" className="extra-info-field"
                                    onChange={this.onMetadataChanged} maxLength="1999"></textarea>
                            </p>
                            <p>
                                <label htmlFor="uploader-name">{t('upload:uploader.name')}</label><br />
                                <input type="text" id="uploader-name" className="extra-info-field"
                                    onChange={this.onMetadataChanged} maxLength="100" />
                            </p>
                            <p>
                                <label htmlFor="uploader-email">{t('upload:uploader.email')}</label><br />
                                <input type="email" id="uploader-email" className="extra-info-field"
                                    onChange={this.onMetadataChanged} maxLength="255" />
                            </p>
                            <p>
                                <label htmlFor="uploader-tel">{t('upload:uploader.tel')}</label><br />
                                <input type="tel" id="uploader-tel" className="extra-info-field"
                                    onChange={this.onMetadataChanged} pattern="^[0-9-+\s()]*$" maxLength="20" />
                            </p>
                            <p>
                                <label htmlFor="uploader-org">{t('upload:uploader.organization')}</label><br />
                                <input type="text" id="uploader-org" className="extra-info-field"
                                    onChange={this.onMetadataChanged} maxLength="100" />
                            </p>
                            <p className="text-right">
                                <button type="button" id="done-button" className="rounded-button submit-btn"
                                    onClick={this.onFormDone} disabled={!this.files_can_done}
                                >{t('upload:action.done')}</button>
                            </p>
                        </div>
                    </Col>
                    <Col xsHidden sm={2} md={3}></Col>
                </Row>
            </div>
        );
    }

    render_folder_bad = (t, upload_disabler) => {
        return (
            <div>
                {upload_disabler}
                <Row>
                    <Col xsHidden sm={2} md={3}></Col>
                    <Col xs={12} sm={8} md={6}>
                        <div id="folder-failure">
                            <h2>{t('upload:folder-fail.header')}</h2>
                            <p>{t('upload:folder-fail.paragraph1')}</p>
                            <p>{t('upload:folder-fail.paragraph2')} {t('upload:folder-fail.paragraph3')}</p>
                        </div>
                    </Col>
                    <Col xsHidden sm={2} md={3}></Col>
                </Row>
            </div>
        );
    }

    render_thank_you = (t, upload_disabler) => {
        return (
            <div>
                {upload_disabler}
                <Row>
                    <Col xsHidden sm={2} md={3}></Col>
                    <Col xs={12} sm={8} md={6}>
                        <div id="thank-you">
                            <h2>{t('upload:thank-you.header')} <br /> {t('upload:thank-you.info')}</h2>
                        </div>
                    </Col>
                    <Col xsHidden sm={2} md={3}></Col>
                </Row>
            </div>
        );
    }

} // end class Upload

export default withTranslation()(Upload);

Upload.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
}