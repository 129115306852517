import React from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import * as $ from 'jquery';
//import util from 'util';

// i18n:
import { withTranslation } from 'react-i18next';

import poliisiLogoFi from '../images/logos/PYT_FI.png';
import poliisiLogoSe from '../images/logos/PYT_SE.png';
import poliisiLogoEn from '../images/logos/PYT_EN.png';
import isfpLogoFi from '../images/logos/ISFP-FI.svg';
import isfpLogoSe from '../images/logos/ISFP-SE.svg';


class Layout extends React.Component {

    render() {
        const { t,  i18n  } = this.props;

        // Debug:
        //console.log("Layout lang: " + util.inspect(i18n.language));
        //console.log("Layout API-host: " + this.props.api_host);

        // Helper function for language change:
        const changeLanguage = (lng) => {
            i18n.changeLanguage(lng);
        }
        // Helper function for content text size change:
        const changeTextSize = (increase) => {
            const main = $('#main');
            const sizes = ['tx-xs', 'tx-sm', 'tx-md', 'tx-lg', 'tx-xl'];
            let textSize = sizes.indexOf('tx-md');

            Array.prototype.forEach.call(sizes, (size, idx) => {
                if (main.hasClass(size))
                    textSize = idx;
            });
            if (increase && ++textSize >= sizes.length) {
                textSize = sizes.length - 1;
            } else if (!increase && --textSize < 0) {
                textSize = 0;
            }
            main.removeClass(sizes.join(' ')).addClass(sizes[textSize]);
        }

        // Define language change buttons.
        // Don't render the one currently chosen.
        let fiButton = <button onClick={() => changeLanguage('fi')} className="language-flag">{t('languages.fi')}</button>;
        let enButton = <button onClick={() => changeLanguage('en')} className="language-flag">{t('languages.en')}</button>;
        let seButton = <button onClick={() => changeLanguage('sv')} className="language-flag">{t('languages.sv')}</button>;
        let isfpLogo = isfpLogoFi;
        let poliisiLogo = poliisiLogoFi;     

        const currentLang = (i18n.language || '').substr(0, 2);
        if (currentLang === 'fi') {
            // 'fi-FI'
            fiButton = '';
        } else if (currentLang === 'en') {
            // 'en-US'
            enButton = '';
            poliisiLogo = poliisiLogoEn;
        } else if (currentLang === 'sv') {
            // 'sv-SE'
            seButton = '';
            isfpLogo = isfpLogoSe;
            poliisiLogo = poliisiLogoSe;
        }

        let test_site_logo = '';
        if (this.props.appconfig.DisplayTestBackground) {
            const body_element = $('body')[0];
            if (body_element) {
                body_element.classList.add('test');
            }
            test_site_logo = (
                <span id="test-indicator" className="text-uppercase"> &ndash; {t('site.test-indicator')}</span>
            );
        }

        return (
            <div>
                <header>
                    <div id="settings" className="text-right">
                        <span id="language-flags">
                            {fiButton}
                            {seButton}
                            {enButton}
                        </span>
                        <span id="text-sizes">
                            <button className="text-size" onClick={() => changeTextSize(false)}>-a</button>
                            <button className="text-size" onClick={() => changeTextSize(true)}>+A</button>
                        </span>
                    </div>
                    <div id="heading">
                        <Grid fluid>
                            <Row>
                                <Col xs={5} sm={4} md={3} id="logo-wrapper">
                                    <img src={poliisiLogo} className="img-responsive" id="poliisi-logo" alt="Poliisi logo" />
                                </Col>
                                <Col xs={7} sm={8} md={6}>
                                    <h1>{t('site.title')} {test_site_logo}</h1>
                                    <h2>{t('site.sub-title')}</h2>
                                </Col>
                                <Col xsHidden smHidden md={3}></Col>
                            </Row>
                        </Grid>
                    </div>
                </header>
                <main id="main">
                    <Grid fluid>
                        {this.props.children}
                    </Grid>
                </main>
                <footer>
                    <Grid fluid>
                        <Row>
                            <Col xs={6} sm={3} className="footer-text">
                                <strong className="text-uppercase">{t('footer.contact-info')}</strong><br />
                                <a href={t('footer.url.service-points')} target="_blank" rel="noopener noreferrer"
                                >{t('footer.service-points')}</a><br />
                                {t('footer.email')}: <br />
                                {t('footer.url.email')}<br />
                                <a target="_blank" href={t('footer.url.feedback')} rel="noopener noreferrer"
                                >{t('footer.feedback')}</a>
                            </Col>
                            <Col xs={6} sm={3} className="footer-text">
                                <a href={t('footer.url.customer-service')} target="_blank" rel="noopener noreferrer"
                                >{t('footer.customer-service')}</a><br />
                                <a href={t('footer.url.site-info')} target="_blank" rel="noopener noreferrer"
                                >{t('footer.site-info')}</a><br />
                                <a href={t('footer.url.data-subjects')} target="_blank" rel="noopener noreferrer"
                                >{t('footer.data-subjects')}</a><br />
                                <br />
                                <span className="text-uppercase">&copy; {t('footer.copyright')}</span>
                            </Col>
                            <Col xs={12} sm={6} className="footer-logo">
                                <img src={isfpLogo} className="img-responsive" id="isfp-logo" alt="ISFP logo" />
                            </Col>
                        </Row>
                    </Grid>
                </footer>
            </div>
        );
    }
}

export default withTranslation()(Layout);