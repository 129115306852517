import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';


i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'fi',

        // Namespaces used:
        ns: ['translations', 'home', 'upload'],
        defaultNS: 'translations',

        debug: true,
        keySeparator: '.', // this is the default
        nsSeparator: ':', // this is the default

        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ',',
            format: function (value, format, lng) {
                if (format === 'uppercase') return value.toUpperCase();
                return value;
            }
        },

        react: {
            defaultTransParent: 'div', // needed for preact
            useSuspense: true
        }
    });

export default i18n;