import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';


class Home extends React.Component {

    render() {
        const { t } = this.props;

        return (
            <Row>
                <Col xsHidden sm={2} md={3}></Col>
                <Col xs={12} sm={8} md={6}>
                    <div id="landing">
                        <h1>{t('home:landing.header')}</h1>
                        <p>{t('home:landing.paragraph1')}</p>
                        <p>{t('home:landing.paragraph2')}</p>
                    </div>
                </Col>
                <Col xsHidden sm={2} md={3}></Col>
            </Row>
        )
    }
}

export default withTranslation('translations')(Home);