import React from 'react';

import poliisiLogoFi from '../images/logos/PYT_FI.png';


class Loader extends React.Component {

    render() {
        return (
            <div id="loader">
                <div>
                    <img src={poliisiLogoFi} className="img-responsive" id="poliisi-logo" alt="Poliisi logo" />
                </div>
            </div>
        );
    }
}

export default Loader;