import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';


class ThankYou extends React.Component {

    render() {
        const { t } = this.props;

        return (
            <Row>
                <Col xsHidden sm={2} md={3}></Col>
                <Col xs={12} sm={8} md={6}>
                    <div id="thank-you">
                        <h2>{t('upload:thank-you.header')} <br /> {t('upload:thank-you.info')}</h2>
                    </div>
                </Col>
                <Col xsHidden sm={2} md={3}></Col>
            </Row>
        );
    }
}

export default withTranslation('translations')(ThankYou);