import React, { Suspense } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";

import Loader from './components/Loader';
import Layout from './components/Layout';
import Home from './components/Home';
import ThankYou from './components/ThankYou';
import Upload from './components/Upload';


class App extends React.Component {
    constructor() {
        super();

        this.state = {
            api_host: null,
            wait_for_appconfig: true
        };

        // We may need to wait for appconfig to appear.
        this.interval = null;

        // API
        if (window.appconfig && false) {
            this.state.api_host = window.appconfig.APIhost;
            this.state.wait_for_appconfig = false;
        } else {
            this.state.api_host = window.location.origin;
        }
    }

    componentDidMount = () => {
        if (window.appconfig) {
            this.setState({ wait_for_appconfig: false });
        } else {
            // There was no appconfig at the time of creating <Layout />.
            //console.log(`XXX No appconfig in componentDidMount!`);

            // Start waiting for it to appear.
            // <script async defer></script> might appear later.
            this.interval = setInterval(() => this.setState({ time: Date.now() }), 500);
        }
    }

    componentDidUpdate = (prevProps) => {
        if (this.state.wait_for_appconfig) {
            //console.log(`XXX No appconfig in componentDidUpdate!`);
            if (window.appconfig) {
                //console.log(`XXX Finally got appconfig in componentDidUpdate!`);
                // Finally! We have appconfig.
                // Stop waiting for it to appear.
                clearInterval(this.interval);

                this.setState({
                    api_host: window.appconfig.APIhost,
                    wait_for_appconfig: false
                });
            }
        } // if (this.state.wait_for_appconfig)
    }

    render() {
        const appConfig = this.state.wait_for_appconfig ? {
            APIhost: this.state.api_host,
            DisplayTestBackground: false,
            reCAPTCHA_PublicKey: null
        } : window.appconfig;

        // cf. https://github.com/nfl/react-helmet
        return (
            <Suspense fallback={<Loader />}>
                <Layout appconfig={appConfig}>
                    <BrowserRouter>
                        <Switch>
                            <Route exact path='/' component={Home} />
                            <Route exact path='/kiitos' component={ThankYou} />
                            <Route path='/:folderName' render={(props) => {
                                return (<Upload appconfig={window.appconfig} {...props} />);
                            }} />
                        </Switch>
                    </BrowserRouter>
                </Layout>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Pouta - Poliisi</title>
                </Helmet>
            </Suspense>
        );
    }
}

export default App;